import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Container,
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Fab,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    MenuItem,
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';

function Expenses() {
    const [expenses, setExpenses] = useState([]);
    const [isConnected, setIsConnected] = useState(false);
    const [open, setOpen] = useState(false);
    const [currentExpense, setCurrentExpense] = useState({
        name: '',
        category: '',
        date: '',
        amount: '',
        description: '',
    });
    const [showReauthDialog, setShowReauthDialog] = useState(false); // State to control reauthorization dialog
    const categories = ['Fuel', 'Equipment', 'Supplies', 'Labor', 'Other'];
    const baseURL = process.env.REACT_APP_API_URL || 'https://old.landscapingsoftwarepro.com/backend';

    useEffect(() => {
        checkQuickBooksConnection();
        fetchExpenses();
    }, []);

    // Check QuickBooks connection status
    const checkQuickBooksConnection = async () => {
        try {
            const token = localStorage.getItem('accessToken') || localStorage.getItem('token');
            if (!token) throw new Error('Access token missing. Please log in again.');

            const response = await axios.get(`${baseURL}/quickbooks/status`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            setIsConnected(response.data.connected);
        } catch (error) {
            console.error('Error checking QuickBooks connection:', error.response?.data || error.message);
        }
    };

    // Fetch expenses from both QuickBooks and manual entries
    const fetchExpenses = async () => {
        try {
            const token = localStorage.getItem('accessToken') || localStorage.getItem('token');
            if (!token) throw new Error('Access token missing. Please log in again.');

            const manualExpensesResponse = await axios.get(`${baseURL}/expenses`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const manualExpenses = Array.isArray(manualExpensesResponse.data) ? manualExpensesResponse.data : [];

            const quickBooksResponse = await axios.get(`${baseURL}/quickbooks/expenses`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const quickBooksExpenses = Array.isArray(quickBooksResponse.data) ? quickBooksResponse.data : [];

            setExpenses([...manualExpenses, ...quickBooksExpenses]);
        } catch (error) {
            console.error('Error fetching expenses:', error.response?.data || error.message);
            if (error.response && error.response.status === 401) {
                // If token is invalid, show the reauthorization dialog
                setShowReauthDialog(true);
            }
        }
    };

    // QuickBooks connection button
    const handleQuickBooksConnect = async() => {
        //window.location.href = `${baseURL}/quickbooks/connect`;
        try {
        const token = localStorage.getItem('accessToken') || localStorage.getItem('token');
        
        const response = await axios.get(`${baseURL}/quickbooks/connect`, {
            headers: { Authorization: `Bearer ${token}`},
            
        });
        console.log(response);
        window.location.href = response.data.url;
        }catch (error) {
            console.error('Error connecting:', error.response?.data || error.message);
            alert('Failed to connect. Please try again.');
        }
    };

    // Import expenses from QuickBooks
    const handleImportExpenses = async () => {
        try {
            const token = localStorage.getItem('accessToken') || localStorage.getItem('token');
            if (!token) throw new Error('Access token missing. Please log in again.');

            const response = await axios.get(`${baseURL}/quickbooks/expenses`, {
                headers: { Authorization: `Bearer ${token}` },
                
            });

            if (response.data.message) {
                alert(response.data.message);
                fetchExpenses(); // Refresh expenses list
            }
        } catch (error) {
            console.error('Error importing expenses:', error.response?.data || error.message);
            alert('Failed to import expenses. Please try again.');
        }
    };

    // Open the expense dialog for editing or adding
    const handleClickOpen = (expense = null) => {
        if (expense) {
            setCurrentExpense(expense);
        } else {
            setCurrentExpense({
                name: '',
                category: '',
                date: '',
                amount: '',
                description: '',
            });
        }
        setOpen(true);
    };

    // Close the expense dialog
    const handleClose = () => {
        setOpen(false);
        setCurrentExpense({
            name: '',
            category: '',
            date: '',
            amount: '',
            description: '',
        });
    };

    // Handle input changes in the expense dialog
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentExpense({ ...currentExpense, [name]: value });
    };

    // Save the expense (edit or add)
    const handleSaveExpense = async () => {
        try {
            const token = localStorage.getItem('accessToken') || localStorage.getItem('token');
            if (!token) throw new Error('Access token missing. Please log in again.');

            if (currentExpense.id) {
                await axios.put(`${baseURL}/expenses/${currentExpense.id}`, currentExpense, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            } else {
                await axios.post(`${baseURL}/expenses`, currentExpense, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            }
            fetchExpenses();
            handleClose();
        } catch (error) {
            console.error('Error saving expense:', error.response?.data || error.message);
        }
    };

    // Delete an expense
    const handleDeleteExpense = async (id) => {
        try {
            const token = localStorage.getItem('accessToken') || localStorage.getItem('token');
            if (!token) throw new Error('Access token missing. Please log in again.');

            await axios.delete(`${baseURL}/expenses/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            fetchExpenses();
        } catch (error) {
            console.error('Error deleting expense:', error.response?.data || error.message);
        }
    };

    // Redirect the user to reconnect to QuickBooks
    const handleReconnectQuickBooks = () => {
        window.location.href = `${baseURL}/quickbooks/connect`;
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Expenses
            </Typography>

            {!isConnected && (
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleQuickBooksConnect}
                    sx={{ mb: 2 }}
                >
                    Connect to QuickBooks
                </Button>
            )}

            {isConnected && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleImportExpenses}
                    sx={{ mb: 2 }}
                >
                    Import Expenses from QuickBooks
                </Button>
            )}

            <Fab
                color="primary"
                aria-label="add"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                onClick={() => handleClickOpen()}
            >
                <Add />
            </Fab>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Source</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {expenses.length > 0 ? (
                            expenses.map((expense) => (
                                <TableRow key={expense.id}>
                                    <TableCell>{expense.name || 'No Name'}</TableCell>
                                    <TableCell>{expense.category || 'No Category'}</TableCell>
                                    <TableCell>{expense.date || 'No Date'}</TableCell>
                                    <TableCell>${parseFloat(expense.amount).toFixed(2)}</TableCell>
                                    <TableCell>{expense.source || 'Manual'}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleClickOpen(expense)}
                                        >
                                            <Edit />
                                        </IconButton>
                                        <IconButton
                                            color="secondary"
                                            onClick={() => handleDeleteExpense(expense.id)}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    No expenses available.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{currentExpense.id ? 'Edit Expense' : 'Add Expense'}</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Name"
                        name="name"
                        fullWidth
                        margin="normal"
                        value={currentExpense.name}
                        onChange={handleInputChange}
                    />
                    <TextField
                        select
                        label="Category"
                        name="category"
                        fullWidth
                        margin="normal"
                        value={currentExpense.category}
                        onChange={handleInputChange}
                    >
                        {categories.map((category) => (
                            <MenuItem key={category} value={category}>
                                {category}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        label="Date"
                        name="date"
                        type="date"
                        fullWidth
                        margin="normal"
                        value={currentExpense.date}
                        onChange={handleInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        label="Amount"
                        name="amount"
                        type="number"
                        fullWidth
                        margin="normal"
                        value={currentExpense.amount}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="Description"
                        name="description"
                        fullWidth
                        margin="normal"
                        value={currentExpense.description}
                        onChange={handleInputChange}
                        multiline
                        rows={3}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveExpense} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Reauthorization Dialog */}
            <Dialog open={showReauthDialog} onClose={() => setShowReauthDialog(false)}>
                <DialogTitle>QuickBooks Reauthorization Required</DialogTitle>
                <DialogContent>
                    <Typography>
                        Your QuickBooks connection has expired. Please reconnect.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleReconnectQuickBooks} color="primary">
                        Reconnect
                    </Button>
                    <Button onClick={() => setShowReauthDialog(false)} color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default Expenses;
