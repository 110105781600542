import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const locales = {
    'en-US': require('date-fns/locale/en-US'),
};
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

function CalendarPage() {
    const [events, setEvents] = useState([]);

    // Base URL for API calls
    const baseURL = process.env.REACT_APP_API_URL || 'https://old.landscapingsoftwarepro.com/backend';

    // Fetch jobs data
    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken') || localStorage.getItem('token');
                if (!accessToken) throw new Error('Access token missing. Please log in again.');

                const response = await axios.get(`${baseURL}/jobs`, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });

                // Transform job data to calendar events
                const jobEvents = response.data.map((job) => ({
                    id: job.id,
                    title: job.name,
                    start: new Date(job.start_date), // Start date
                    end: new Date(job.end_date),     // End date
                    allDay: true,
                }));

                setEvents(jobEvents);
            } catch (error) {
                console.error('Error fetching jobs for calendar:', error.message);
            }
        };

        fetchJobs();
    }, [baseURL]);

    return (
        <div style={{ height: '80vh', padding: '20px' }}>
            <h2>Job Calendar</h2>
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: '100%' }}
            />
        </div>
    );
}

export default CalendarPage;
