import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Container,
    Typography,
    Paper,
    Grid,
    CircularProgress,
    MenuItem,
    TextField,
} from '@mui/material';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { motion } from 'framer-motion';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Reports() {
    const [totalIncome, setTotalIncome] = useState(0);
    const [totalExpenses, setTotalExpenses] = useState(0);
    const [filter, setFilter] = useState('monthly'); // Default filter: 'monthly'
    const [chartData, setChartData] = useState({ labels: [], income: [], expenses: [] });
    const [loading, setLoading] = useState(true);

    // Base URL for API calls
    const baseURL = process.env.REACT_APP_API_URL || 'https://old.landscapingsoftwarepro.com/backend';

    // Fetch reports and chart data
    const fetchReports = async () => {
        try {
            const accessToken = localStorage.getItem('accessToken') || localStorage.getItem('token');
            if (!accessToken) throw new Error('Access token missing. Please log in again.');

            const headers = { Authorization: `Bearer ${accessToken}` };

            // Fetch total income and expenses
            const incomeResponse = await axios.get(`${baseURL}/reports/total-income?filter=${filter}`, { headers });
            const expensesResponse = await axios.get(`${baseURL}/reports/total-expenses?filter=${filter}`, { headers });
            setTotalIncome(parseFloat(incomeResponse.data.total_income) || 0);
            setTotalExpenses(parseFloat(expensesResponse.data.total_expenses) || 0);

            // Fetch chart data
            const chartResponse = await axios.get(`${baseURL}/reports/chart-data?filter=${filter}`, { headers });
            setChartData({
                labels: chartResponse.data.labels || [],
                income: chartResponse.data.income || [],
                expenses: chartResponse.data.expenses || [],
            });

            setLoading(false);
        } catch (error) {
            console.error('Error fetching reports:', error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchReports();
    }, [filter]); // Fetch data when the filter changes

    // Net income calculation
    const netIncome = totalIncome - totalExpenses;

    // Chart configuration
    const chartConfig = {
        labels: chartData.labels,
        datasets: [
            {
                label: 'Income',
                data: chartData.income,
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
            {
                label: 'Expenses',
                data: chartData.expenses,
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
        ],
    };

    return (
        <Container>
            <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
            >
                <Typography variant="h4" align="center" gutterBottom>
                    Financial Reports
                </Typography>
            </motion.div>

            {/* Filter Dropdown */}
            <Grid container justifyContent="center" spacing={2} style={{ marginBottom: '20px' }}>
                <Grid item>
                    <TextField
                        select
                        label="Filter"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        variant="outlined"
                        size="small"
                    >
                        <MenuItem value="weekly">Weekly</MenuItem>
                        <MenuItem value="monthly">Monthly</MenuItem>
                        <MenuItem value="yearly">Yearly</MenuItem>
                    </TextField>
                </Grid>
            </Grid>

            {/* Loading State */}
            {loading ? (
                <CircularProgress style={{ display: 'block', margin: 'auto' }} />
            ) : (
                <>
                    {/* Financial Summary */}
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <Paper style={{ padding: '20px', textAlign: 'center' }}>
                                <Typography variant="h6">Gross Income</Typography>
                                <Typography variant="h4" style={{ color: 'green' }}>
                                    ${totalIncome.toFixed(2)}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Paper style={{ padding: '20px', textAlign: 'center' }}>
                                <Typography variant="h6">Expenses</Typography>
                                <Typography variant="h4" style={{ color: 'red' }}>
                                    ${totalExpenses.toFixed(2)}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Paper style={{ padding: '20px', textAlign: 'center' }}>
                                <Typography variant="h6">Net Income</Typography>
                                <Typography
                                    variant="h4"
                                    style={{ color: netIncome >= 0 ? 'green' : 'red' }}
                                >
                                    ${netIncome.toFixed(2)}
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>

                    {/* Bar Chart */}
                    <Grid container spacing={3} style={{ marginTop: '20px' }}>
                        <Grid item xs={12}>
                            <Paper style={{ padding: '20px' }}>
                                <Typography variant="h6" gutterBottom>
                                    Income vs Expenses
                                </Typography>
                                <Bar data={chartConfig} />
                            </Paper>
                        </Grid>
                    </Grid>
                </>
            )}
        </Container>
    );
}

export default Reports;
