import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Container,
    Typography,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Fab,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    MenuItem,
} from '@mui/material';
import { Add, Edit, Delete, CheckCircle } from '@mui/icons-material';

function Invoices() {
    const [invoices, setInvoices] = useState([]);
    const [clients, setClients] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentInvoice, setCurrentInvoice] = useState({
        client_id: '',
        invoice_number: '',
        issue_date: '',
        due_date: '',
        total_amount: '',
    });

    const baseURL = process.env.REACT_APP_API_URL || 'https://old.landscapingsoftwarepro.com/backend';

    useEffect(() => {
        fetchInvoices();
        fetchClients();
    }, []);

    const fetchInvoices = async () => {
        try {
            const token = localStorage.getItem('accessToken') || localStorage.getItem('token');
            if (!token) throw new Error('Access token missing.');

            const response = await axios.get(`${baseURL}/invoices`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setInvoices(response.data);
        } catch (error) {
            console.error('Error fetching invoices:', error.message);
        }
    };

    const fetchClients = async () => {
        try {
            const token = localStorage.getItem('accessToken') || localStorage.getItem('token');
            if (!token) throw new Error('Access token missing.');

            const response = await axios.get(`${baseURL}/clients`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setClients(response.data);
        } catch (error) {
            console.error('Error fetching clients:', error.message);
        }
    };

    const handleClickOpen = (invoice = null) => {
        if (invoice) {
            setCurrentInvoice(invoice);
        } else {
            setCurrentInvoice({
                client_id: '',
                invoice_number: '',
                issue_date: '',
                due_date: '',
                total_amount: '',
            });
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentInvoice({
            client_id: '',
            invoice_number: '',
            issue_date: '',
            due_date: '',
            total_amount: '',
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentInvoice({ ...currentInvoice, [name]: value });
    };

    const handleSaveInvoice = async () => {
        try {
            const token = localStorage.getItem('accessToken') || localStorage.getItem('token');
            if (!token) throw new Error('Access token missing.');

            if (currentInvoice.id) {
                // Update existing invoice
                await axios.put(`${baseURL}/invoices/${currentInvoice.id}`, currentInvoice, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            } else {
                // Create new invoice
                await axios.post(`${baseURL}/invoices`, currentInvoice, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            }
            fetchInvoices();
            handleClose();
        } catch (error) {
            console.error('Error saving invoice:', error.message);
        }
    };

    const handleDeleteInvoice = async (id) => {
        try {
            const token = localStorage.getItem('accessToken') || localStorage.getItem('token');
            if (!token) throw new Error('Access token missing.');

            await axios.delete(`${baseURL}/invoices/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            fetchInvoices();
        } catch (error) {
            console.error('Error deleting invoice:', error.message);
        }
    };

    const handleMarkAsPaid = async (id) => {
        try {
            const token = localStorage.getItem('accessToken') || localStorage.getItem('token');
            if (!token) throw new Error('Access token missing.');

            await axios.put(`${baseURL}/invoices/${id}/mark-paid`, {}, {
                headers: { Authorization: `Bearer ${token}` },
            });
            fetchInvoices();
        } catch (error) {
            console.error('Error marking invoice as paid:', error.message);
        }
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Invoices
            </Typography>
            <Fab
                color="primary"
                aria-label="add"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                onClick={() => handleClickOpen()}
            >
                <Add />
            </Fab>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Invoice Number</TableCell>
                            <TableCell>Client</TableCell>
                            <TableCell>Issue Date</TableCell>
                            <TableCell>Due Date</TableCell>
                            <TableCell>Total Amount</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoices.map((invoice) => (
                            <TableRow key={invoice.id}>
                                <TableCell>{invoice.invoice_number}</TableCell>
                                <TableCell>{invoice.client_name}</TableCell>
                                <TableCell>{invoice.issue_date}</TableCell>
                                <TableCell>{invoice.due_date}</TableCell>
                                <TableCell>${parseFloat(invoice.total_amount).toFixed(2)}</TableCell>
                                <TableCell>{invoice.status}</TableCell>
                                <TableCell>
                                    {/* Edit Button */}
                                    <IconButton
                                        color="primary"
                                        onClick={() => handleClickOpen(invoice)}
                                    >
                                        <Edit />
                                    </IconButton>
                                    {/* Delete Button */}
                                    <IconButton
                                        color="secondary"
                                        onClick={() => handleDeleteInvoice(invoice.id)}
                                    >
                                        <Delete />
                                    </IconButton>
                                    {/* Mark as Paid Button */}
                                    <IconButton
                                        color="success"
                                        onClick={() => handleMarkAsPaid(invoice.id)}
                                        disabled={invoice.status === 'Paid'}
                                    >
                                        <CheckCircle />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{currentInvoice.id ? 'Edit Invoice' : 'Add Invoice'}</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Invoice Number"
                        name="invoice_number"
                        fullWidth
                        margin="normal"
                        value={currentInvoice.invoice_number}
                        onChange={handleInputChange}
                    />
                    <TextField
                        select
                        label="Client"
                        name="client_id"
                        fullWidth
                        margin="normal"
                        value={currentInvoice.client_id}
                        onChange={handleInputChange}
                    >
                        {clients.map((client) => (
                            <MenuItem key={client.id} value={client.id}>
                                {client.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        label="Issue Date"
                        name="issue_date"
                        type="date"
                        fullWidth
                        margin="normal"
                        value={currentInvoice.issue_date}
                        onChange={handleInputChange}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        label="Due Date"
                        name="due_date"
                        type="date"
                        fullWidth
                        margin="normal"
                        value={currentInvoice.due_date}
                        onChange={handleInputChange}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        label="Total Amount"
                        name="total_amount"
                        type="number"
                        fullWidth
                        margin="normal"
                        value={currentInvoice.total_amount}
                        onChange={handleInputChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveInvoice} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default Invoices;
