import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box,
    Button,
    TextField,
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    ListItemSecondaryAction,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { Add, Edit, Delete, Email, Textsms } from '@mui/icons-material';

const TeamMembers = () => {
    const [teamMembers, setTeamMembers] = useState([]);
    const [newMember, setNewMember] = useState({ name: '', role: '', email: '', phone: '', avatar: '' });
    const [editMember, setEditMember] = useState(null);
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

    useEffect(() => {
        fetchTeamMembers();
    }, []);

    const fetchTeamMembers = () => {
        axios
            .get('/backend/team-members', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((response) => {
                setTeamMembers(response.data);
            })
            .catch((error) => {
                console.error('Error fetching team members', error);
            });
    };

    const addTeamMember = () => {
        axios
            .post('/backend/team-members', newMember, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then(() => {
                fetchTeamMembers();
                setNewMember({ name: '', role: '', email: '', phone: '', avatar: '' });
                setIsAddDialogOpen(false);
            })
            .catch((error) => {
                console.error('Error adding team member', error);
            });
    };

    const deleteTeamMember = (id) => {
        axios
            .delete(`/backend/team-members/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then(() => {
                fetchTeamMembers();
            })
            .catch((error) => {
                console.error('Error deleting team member', error);
            });
    };

    const updateTeamMember = () => {
        axios
            .put(`/backend/team-members/${editMember.id}`, editMember, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then(() => {
                fetchTeamMembers();
                setEditMember(null);
            })
            .catch((error) => {
                console.error('Error updating team member', error);
            });
    };

    const getInitials = (name) => {
        const names = name.split(' ');
        return names.length > 1
            ? `${names[0][0]}${names[1][0]}`.toUpperCase()
            : `${names[0][0]}`.toUpperCase();
    };

    const handleEmailClick = (email) => {
        console.log(`Email clicked for: ${email}`);
        // Placeholder for email functionality
    };

    const handleTextClick = (phone) => {
        console.log(`Text clicked for: ${phone}`);
        // Placeholder for texting functionality
    };

    return (
        <Box sx={{ p: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h4">Team Members</Typography>
                <IconButton color="primary" onClick={() => setIsAddDialogOpen(true)}>
                    <Add fontSize="large" />
                </IconButton>
            </Box>

            <List>
                {teamMembers.length > 0 ? (
                    teamMembers.map((member) => (
                        <ListItem key={member.id} divider>
                            <ListItemAvatar>
                                <Avatar src={member.avatar || undefined}>
                                    {!member.avatar && getInitials(member.name)}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={`${member.name} (${member.role})`}
                                secondary={`Email: ${member.email} | Phone: ${member.phone}`}
                            />
                            <ListItemSecondaryAction>
                                <IconButton color="info" onClick={() => handleEmailClick(member.email)}>
                                    <Email />
                                </IconButton>
                                <IconButton color="success" onClick={() => handleTextClick(member.phone)}>
                                    <Textsms />
                                </IconButton>
                                <IconButton color="primary" onClick={() => setEditMember(member)}>
                                    <Edit />
                                </IconButton>
                                <IconButton color="error" onClick={() => deleteTeamMember(member.id)}>
                                    <Delete />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))
                ) : (
                    <Typography>No team members found.</Typography>
                )}
            </List>

            <Dialog open={isAddDialogOpen} onClose={() => setIsAddDialogOpen(false)}>
                <DialogTitle>Add Team Member</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Name"
                        value={newMember.name}
                        onChange={(e) => setNewMember({ ...newMember, name: e.target.value })}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Role"
                        value={newMember.role}
                        onChange={(e) => setNewMember({ ...newMember, role: e.target.value })}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Email"
                        type="email"
                        value={newMember.email}
                        onChange={(e) => setNewMember({ ...newMember, email: e.target.value })}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Phone"
                        value={newMember.phone}
                        onChange={(e) => setNewMember({ ...newMember, phone: e.target.value })}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Avatar URL"
                        placeholder="Enter image URL"
                        value={newMember.avatar}
                        onChange={(e) => setNewMember({ ...newMember, avatar: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsAddDialogOpen(false)}>Cancel</Button>
                    <Button variant="contained" onClick={addTeamMember}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>

            {editMember && (
                <Dialog open={Boolean(editMember)} onClose={() => setEditMember(null)}>
                    <DialogTitle>Edit Team Member</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Name"
                            value={editMember.name}
                            onChange={(e) => setEditMember({ ...editMember, name: e.target.value })}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Role"
                            value={editMember.role}
                            onChange={(e) => setEditMember({ ...editMember, role: e.target.value })}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Email"
                            type="email"
                            value={editMember.email}
                            onChange={(e) => setEditMember({ ...editMember, email: e.target.value })}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Phone"
                            value={editMember.phone}
                            onChange={(e) => setEditMember({ ...editMember, phone: e.target.value })}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Avatar URL"
                            placeholder="Enter image URL"
                            value={editMember.avatar}
                            onChange={(e) => setEditMember({ ...editMember, avatar: e.target.value })}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setEditMember(null)}>Cancel</Button>
                        <Button variant="contained" onClick={updateTeamMember}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Box>
    );
};

export default TeamMembers;
